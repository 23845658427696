:root {
  --main-color: #1a8fff;
  --text-color: #fff;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

@media (max-width:600px) {
  .css-r8wyxg text {
    font-size: 30px;
  }
}
@media (max-width:400px) {
  .css-r8wyxg text {
    font-size: 20px;
  }
}

/* GRID */

.daily-view-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  margin: 12px 0;
}

.daily-view-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.daily-view-col-center {
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.daily-view-col-start {
  justify-content: flex-start;
  text-align: left;
}

.daily-view-col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.daily-view-calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  border-radius: 16px;
  overflow: hidden;
}

.daily-view-calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  padding-top: 2em;
  margin: 4px 0;
}

.daily-view-calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.daily-view-calendar .header .icon:hover {
  transform: scale(1.3);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.daily-view-calendar .header .icon.disabled {
  display: none;
}

.daily-view-calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.daily-view-calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.daily-view-calendar .days {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--text-color);
  font-size: 70%;
  padding: 0.75em 0;
}

.daily-view-calendar .body .cell {
  position: relative;
  height: 5em;
  overflow: hidden;
  transition: 0.25s ease-out;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

.daily-view-calendar .body .cell.selected {
  background-color: var(--neutral-color);
}

.daily-view-calendar .body .cell.selected .date {
  color: #000;
}

.daily-view-calendar .body {
  display: flex;
  overflow-x: scroll;
  gap: 12px;
  margin: 10px;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.daily-view-calendar .body::-webkit-scrollbar {
  display: none;
}

.daily-view-calendar .body .cell .date {
  position: absolute;
  font-size: 16;
  line-height: 1;
  top: 0.5em;
  right: 0.5em;
  font-weight: 700;
}

.daily-view-calendar .body .cell .moonDate {
  position: absolute;
  font-size: 16;
  line-height: 1;
  bottom: 0.5em;
  left: 0.5em;
  font-weight: 700;
  color: rgba(200, 21, 33, 1);
}

.daily-view-calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.daily-view-calendar .body .daily-view-col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  min-width: calc(100% / 7 - 10.5px);
}

@media (max-width: 600px) {
  .daily-view-calendar .body {
    margin: 6px;
    gap: 6px;
  }

  .daily-view-row {
    gap: 2px;
    margin: 2px 0;
  }

  .daily-view-calendar .body .cell {
    height: 3.5em;
  }

  .daily-view-calendar .body .cell .date {
    font-size: 12px;
  }

  .daily-view-calendar .body .cell .moonDate {
    font-size: 12px;
  }

  .daily-view-calendar .body .daily-view-col {
    min-width: calc(100% / 7 - 5.5px);
  }
}