@font-face {
  font-family: 'GeneralSans-Variable';
  src: url('../resources/fonts/GeneralSans-Variable.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Variable.woff') format('woff');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-VariableItalic';
  src: url('../resources/fonts/GeneralSans-VariableItalic.woff2')
      format('woff2'),
    url('../resources/fonts/GeneralSans-VariableItalic.woff') format('woff');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Extralight';
  src: url('../resources/fonts/GeneralSans-Extralight.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Extralight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-ExtralightItalic';
  src: url('../resources/fonts/GeneralSans-ExtralightItalic.woff2')
      format('woff2'),
    url('../resources/fonts/GeneralSans-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Light';
  src: url('../resources/fonts/GeneralSans-Light.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-LightItalic';
  src: url('../resources/fonts/GeneralSans-LightItalic.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Regular';
  src: url('../resources/fonts/GeneralSans-Regular.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-Italic';
  src: url('../resources/fonts/GeneralSans-Italic.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Italic.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Medium';
  src: url('../resources/fonts/GeneralSans-Medium.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-MediumItalic';
  src: url('../resources/fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Semibold';
  src: url('../resources/fonts/GeneralSans-Semibold.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-SemiboldItalic';
  src: url('../resources/fonts/GeneralSans-SemiboldItalic.woff2')
      format('woff2'),
    url('../resources/fonts/GeneralSans-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('../resources/fonts/GeneralSans-Bold.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-BoldItalic';
  src: url('../resources/fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
    url('../resources/fonts/GeneralSans-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-BlackItalic.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-Black.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-Bold.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-BoldItalic.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial Book';
  src: url('../resources/fonts/Salina-Trial-BookItalic.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-ExtraLight.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-ExtraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-MediumItalic.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-LightItalic.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-Medium.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-ExtraLightItalic.woff2')
      format('woff2'),
    url('../resources/fonts/Salina-Trial-ExtraLightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-Light.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial Book';
  src: url('../resources/fonts/Salina-Trial-Book.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-RegularItalic.woff2')
      format('woff2'),
    url('../resources/fonts/Salina-Trial-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Salina-Trial';
  src: url('../resources/fonts/Salina-Trial-Regular.woff2') format('woff2'),
    url('../resources/fonts/Salina-Trial-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
